import {CustomSVGProps} from "../../types/CustomSVGProps.types";

export function IconCircleSolid48(props: CustomSVGProps) : JSX.Element{
    return (
        <svg {...props}
             width="48"
             height="48"
             viewBox="0 0 48 48"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path d="M48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24Z"
                  fill="black"/>
        </svg>
    );
}

export function IconCircleSolid24(props: CustomSVGProps) : JSX.Element{
    return (
        <svg {...props}
             width="24"
             height="24"
             viewBox="0 0 24 24"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z"
                  fill={props.fill || 'currentColor'} />
        </svg>
    );
}

export function IconCircleSolid16(props: CustomSVGProps) : JSX.Element{
    return (
        <svg {...props}
             width="16"
             height="16"
             viewBox="0 0 16 16"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}