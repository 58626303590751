import classnames from 'classnames';

function MadeWithLove(props: { className?: string }): JSX.Element {
    return (
        <div className={classnames(props.className, 'text-body-10 text-center whitespace-pre-wrap')}>
            <span className="opacity-50">Made with</span>
            <span>&nbsp;🩵&nbsp;</span>
            <span className="opacity-50">by Cineamo</span>
        </div>
    );
}

export default MadeWithLove;
