import { useTranslation } from 'next-i18next';
import React from 'react';

import ProductStatus from '@/components/ui/products/ProductStatus';

import { CinemaDto } from '@cineamo/legacy-frontend-lib/src/models/cinema/CinemaDto.types';

function ProductStatusList(props: { cinema: CinemaDto }) {
    if (!props.cinema) {
        return null;
    }

    const { t } = useTranslation([]);
    const cinemaConfiguration = props.cinema?._embedded?.cinemaConfiguration;

    return (
        <div className="flex flex-col">
            <span className="text-body-bold-10 uppercase opacity-50">{t('common:products')}</span>
            <ProductStatus
                name={t('common:marketing-assistant')}
                status={cinemaConfiguration?.hasMarketingAssistantBooked === true}
            />
            <ProductStatus
                name={t('cinema:event-settings.private-request-available')}
                status={cinemaConfiguration?.isPrivateBookingEnabled === true}
            />
            <ProductStatus
                name={t('cinema:event-settings.public-request-available')}
                status={cinemaConfiguration?.isProgramSuggestionEnabled === true}
            />
            <ProductStatus
                name={t('cinema:cinema.cinema-website')}
                status={cinemaConfiguration?.isWebsiteCustomer === true}
            />
        </div>
    );
}

export default ProductStatusList;
