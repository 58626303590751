import classnames from 'classnames';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import ReactCalendar from 'react-calendar';

import { formatDate_yyyyMMdd } from '../../../helper/date-helper';

export enum CalendarStyle {
    default = 'default',
    light = 'light',
    frameless = 'frameless'
}

export type customArrowLabelType = { prev: JSX.Element; prev2: JSX.Element; next: JSX.Element; next2: JSX.Element };

export type CalendarProps = {
    className?: string;
    style?: CalendarStyle;
    selectedDate: string | Date;
    setSelectedDate: Dispatch<SetStateAction<string>>;
    minDate?: Date;
    maxDate?: Date;
    rawDate?: boolean;
    customArrowLabels?: customArrowLabelType;
    onClickDay?: (value: Date) => void;
};

function Calendar(props: CalendarProps): JSX.Element {
    const { className, style, selectedDate, setSelectedDate, minDate, maxDate, customArrowLabels, onClickDay } = props;

    const roundedClassName = !!props.className && props.className.indexOf('rounded') !== -1 ? '' : 'rounded-8';

    const formattedSelectedDate = new Date(selectedDate);

    const [value, onChange] = useState<Date>();

    let calendarStyle: string;
    switch (style) {
        case CalendarStyle.light:
            calendarStyle = 'bg-darkBlue bg-opacity-20 text-darkBlue';
            break;
        case CalendarStyle.frameless:
            calendarStyle = 'text-darkBlue';
            break;
        default:
            calendarStyle = 'bg-darkBlue bg-opacity-20';
            break;
    }

    useEffect(() => {
        if (value) {
            setSelectedDate(formatDate_yyyyMMdd(value));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
        <div className={classnames(className, calendarStyle, 'p-9 box-border', roundedClassName)}>
            <ReactCalendar
                onChange={(value: Date) => {
                    onChange(value);
                }}
                value={formattedSelectedDate}
                minDate={minDate}
                maxDate={maxDate}
                prevLabel={customArrowLabels?.prev}
                prev2Label={customArrowLabels?.prev2}
                nextLabel={customArrowLabels?.next}
                next2Label={customArrowLabels?.next2}
                onClickDay={(value: Date) => onClickDay && onClickDay(value)}
            />
        </div>
    );
}

export default Calendar;
