import classnames from 'classnames';
import { useTranslation } from 'next-i18next';
import React from 'react';

import { useCinemaStore } from '@cineamo/legacy-frontend-lib/src/store/cinema/cinemaStore';

import { IconCircleSolid16 } from '@cineamo/legacy-frontend-lib/src/icons/symbols/ic-circleSolid';

export function MarketingAssistantActiveLabel() {
    const { t } = useTranslation('marketing-assistant');

    const { cinemaConfiguration } = useCinemaStore();

    const isMarketingAssistantActive = cinemaConfiguration?.hasMarketingAssistantBooked ?? undefined;

    return isMarketingAssistantActive ? (
        <div className="flex flex-col space-y-8">
            <span className="text-body-bold-16 whitespace-pre">{t('marketing-assistant:title')}</span>
            <div className="flex flex-row items-center space-x-8">
                <div className="relative">
                    <IconCircleSolid16 className={classnames('w-12 h-12 flex-none text-cyan  animate-pingWithPause')} />
                    <IconCircleSolid16 className={classnames('absolute top-0 left-0 w-12 h-12 flex-none text-cyan')} />
                </div>
                <span className={classnames('text-body-bold-14 text-cyan')}>{t('dashboard:active')}</span>
            </div>
        </div>
    ) : (
        <div className="flex flex-col space-y-8">
            <span className="text-body-bold-16 whitespace-pre">{t('marketing-assistant:title')}</span>
            <div className="flex flex-row items-center space-x-8">
                <IconCircleSolid16 className={classnames('w-12 h-12 flex-none text-white-50')} />
                <span className={classnames('text-body-bold-14 text-white-50')}>{t('dashboard:inactive')}</span>
            </div>
        </div>
    );
}
