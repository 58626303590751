import { EventRequestState } from '../../constants/request-constants';
import { UrlQueryParameter, buildUrlQuery } from '../../helper/url-query-helper';
import {
    EventRequestCinemaAcceptInputDto,
    EventRequestCinemaDeclineInputDto,
    EventRequestDto,
    EventRequestInputDto,
    EventRequestUserDeclineInputDto
} from '../../models/event-request/EventRequestDto.types';
import { ApiErrorResponse } from '../ApiErrorResponse.types';
import { EventRequestListResponse } from '../ApiListResponse.types';
import { ApiResponse } from '../ApiResponse.types';
import apiClient from '../cineamoApiClient';

// <------------------------ Event Requests ------------------------>

export type GetEventRequestsParams = UrlQueryParameter & {
    cinemaId?: number | string;
    state?: EventRequestState[];
    isPrivate?: 0 | 1 | boolean;
    isCreatedByCinema?: 0 | 1 | boolean;
    isRegularShowtime?: 0 | 1 | boolean;
    category?: string;
    cineamoMovieId?: string;
    userId?: number;
    createdDatetimeStart?: string;
    createdDatetimeEnd?: string;
    showingDatetimeStart?: string;
    showingDatetimeEnd?: string;
    logitude?: number;
    latitude?: number;
};

/**
 * This endpoint returns a list of event requests.
 *
 * @example
 * // Get a list of event requests:
 * getEventRequests({...params});
 *
 * @param params
 * @returns {ApiResponse<EventRequestListResponse>}
 */

export async function getEventRequests(
    params?: GetEventRequestsParams
): Promise<ApiResponse<EventRequestListResponse>> {
    return apiClient
        .get(`/event-requests${buildUrlQuery(params)}`)
        .then((response) => response.data)
        .catch(() => {
            return null;
        });
}

// <------------------------ Event Requests By Cinema ------------------------>

/**
 * This endpoint returns a list of event requests.
 *
 * @example
 * // Get a list of event requests by cinema id:
 * getEventRequests(1, {...params});
 *
 * @param cinemaId
 * @param params
 * @returns {ApiResponse<EventRequestListResponse>}
 */

export async function getEventRequestsByCinemaId(
    cinemaId: number | string,
    params?: UrlQueryParameter
): Promise<ApiResponse<EventRequestListResponse>> {
    return apiClient
        .get(`/event-requests${buildUrlQuery({ ...params, cinema: cinemaId })}`)
        .then((response) => response.data)
        .catch(() => {
            return null;
        });
}

// <------------------------ Event Request ------------------------>

/**
 * This endpoint returns an event request.
 *
 * @example
 * // Get an event request by id:
 * getEventRequestById(1, {...params});
 *
 * @param eventRequestId
 * @param params
 * @returns {ApiResponse<EventRequestDto>}
 */

export async function getEventRequestById(
    eventRequestId: number | string,
    params?: UrlQueryParameter
): Promise<ApiResponse<EventRequestDto>> {
    return apiClient
        .get(`/event-requests/${eventRequestId}${buildUrlQuery({ ...params })}`)
        .then((response) => response.data)
        .catch(() => {
            return null;
        });
}

// <------------------------ Create Event Request ------------------------>

/**
 * This endpoint returns an event request.
 *
 * @example
 * // Create an event request:
 * createEventRequest({...eventRequest});
 *
 * @param eventRequest
 * @returns {ApiResponse<EventRequestDto> | ApiErrorResponse<EventRequestDto>}
 */
export async function createEventRequest(
    eventRequest: EventRequestInputDto
): Promise<ApiResponse<EventRequestDto> | ApiErrorResponse<EventRequestInputDto>> {
    return apiClient
        .post('/event-requests', eventRequest)
        .then((response) => response.data)
        .catch((error) => error);
}

// <------------------------ Update Event Request ------------------------>

/**
 * This endpoint updates an existing event request and returs it.
 *
 * @example
 * // Update an existing event request:
 * updateEventRequest({...eventRequest});
 *
 * @param eventRequest
 * @returns {ApiResponse<EventRequestDto> | ApiErrorResponse<EventRequestDto>}
 */
export async function updateEventRequest(
    eventRequest: EventRequestInputDto
): Promise<ApiResponse<EventRequestDto> | ApiErrorResponse<EventRequestInputDto>> {
    return apiClient
        .patch(`/event-requests/${eventRequest.id}`, eventRequest)
        .then((response) => response.data)
        .catch((error) => error);
}

// <------------------------ Event Request RPC ------------------------>

export async function sendEventRequest(
    eventRequestId: number | string
): Promise<ApiResponse<EventRequestDto> | ApiErrorResponse<EventRequestInputDto>> {
    return apiClient
        .patch(`/event-requests/${eventRequestId}/send`)
        .then((response) => response.data)
        .catch((error) => error);
}

export async function eventRequestRightsAvailable(
    eventRequestId: number | string
): Promise<ApiResponse<EventRequestDto>> {
    return apiClient
        .patch(`/event-requests/${eventRequestId}/rights-available`)
        .then((response) => response.data)
        .catch(() => {
            return null;
        });
}

export async function eventRequestAutoCheckRights(
    eventRequestId: number | string
): Promise<ApiResponse<EventRequestDto>> {
    return apiClient
        .patch(`/event-requests/${eventRequestId}/auto-check-rights`)
        .then((response) => response.data)
        .catch(() => {
            return null;
        });
}

export async function eventRequestAccept(
    eventRequestId: number | string,
    eventRequestCinemaAccept: EventRequestCinemaAcceptInputDto = undefined
): Promise<ApiResponse<EventRequestDto>> {
    return apiClient
        .patch(`/event-requests/${eventRequestId}/accept`, eventRequestCinemaAccept)
        .then((response) => response.data)
        .catch(() => {
            return null;
        });
}

export async function eventRequestDecline(
    eventRequestId: number | string,
    eventRequestCinemaDecline: EventRequestCinemaDeclineInputDto | EventRequestUserDeclineInputDto
): Promise<ApiResponse<EventRequestDto>> {
    return apiClient
        .patch(`/event-requests/${eventRequestId}/decline`, eventRequestCinemaDecline)
        .then((response) => response.data)
        .catch(() => {
            return null;
        });
}

export async function eventRequestRightsNotAvailable(
    eventRequestId: number | string
): Promise<ApiResponse<EventRequestDto>> {
    return apiClient
        .patch(`/event-requests/${eventRequestId}/rights-not-available`)
        .then((response) => response.data)
        .catch(() => {
            return null;
        });
}

export async function eventRequestCancel(
    eventRequestId: number | string,
    eventRequestCinemaCancel: EventRequestCinemaDeclineInputDto
): Promise<ApiResponse<EventRequestDto>> {
    return apiClient
        .patch(`/event-requests/${eventRequestId}/cancel`, eventRequestCinemaCancel)
        .then((response) => response.data)
        .catch(() => {
            return null;
        });
}

export async function eventRequestDateAvailable(
    eventRequestId: number | string,
    eventRequestDate: number
): Promise<ApiResponse<EventRequestDto>> {
    return apiClient
        .patch(`/event-requests/${eventRequestId}/date-available`, { eventRequestDate: eventRequestDate })
        .then((response) => response.data)
        .catch(() => {
            return null;
        });
}

export async function eventRequestSuggestDate(
    eventRequestId: number | string,
    eventRequestCinemaAccept: EventRequestCinemaAcceptInputDto & {
        eventRequestDate: number | string;
    }
): Promise<ApiResponse<EventRequestDto>> {
    return apiClient
        .patch(`/event-requests/${eventRequestId}/suggest-date`, eventRequestCinemaAccept)
        .then((response) => response.data)
        .catch(() => {
            return null;
        });
}

export async function eventRequestNotify(eventRequestId: number | string): Promise<ApiResponse<EventRequestDto>> {
    return apiClient
        .patch(`/event-requests/${eventRequestId}/notify`)
        .then((response) => response.data)
        .catch(() => {
            return null;
        });
}

export async function eventRequestShare(eventRequestId: number | string): Promise<ApiResponse<EventRequestDto>> {
    return apiClient
        .patch(`/event-requests/${eventRequestId}/share`)
        .then((response) => response.data)
        .catch(() => {
            return null;
        });
}
