import ImMarketingAssistant from '@cineamo/legacy-frontend-lib/src/illustrations/im-marketingAssistant.svg';
import { useTranslation } from 'next-i18next';
import Image from 'next/future/image';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import NoDataAvailableYet from '@cineamo/legacy-frontend-lib/src/components/misc/NoDataAvailableYet';
import AdminCard from '@cineamo/legacy-frontend-lib/src/components/ui/cards/AdminCard';
import InfoCardSmallNew from '@cineamo/legacy-frontend-lib/src/components/ui/cards/InfoCardSmallNew';
import LineChart from '@cineamo/legacy-frontend-lib/src/components/ui/chart/LineChart';
import Tabs from '@cineamo/legacy-frontend-lib/src/components/ui/tabs/Tabs';
import { MarketingAssistantActiveLabel } from '@/components/pages/marketing-assistant/MarketingAssistantActiveLabel';
import { MarketingAssistantInactiveCard } from '@/components/pages/marketing-assistant/MarketingAssistantInactiveCard';

import { getUserMoviesCountGrouped } from '@cineamo/legacy-frontend-lib/src/api/analytics/analytics-api';
import { AnalyticsApiGroup } from '@cineamo/legacy-frontend-lib/src/api/analytics/analytics-api.group';

import { loadAsset } from '@cineamo/legacy-frontend-lib/src/helper/asset-helper';
import { dateTodayMinus, formatDate, formatDate_yyyyMMdd } from '@cineamo/legacy-frontend-lib/src/helper/date-helper';
import { tailwindColors } from '@/src/helper/tailwindConfigHelper';

import { useCinemaStore } from '@cineamo/legacy-frontend-lib/src/store/cinema/cinemaStore';

const placeholderImage = loadAsset('/images/placeholders/im-placeholder.png');

export type CinemaDashboardNotificationsSentProps = {
    locale?: string;
};

function CinemaDashboardMarketingAssistant(props: CinemaDashboardNotificationsSentProps): JSX.Element {
    const { locale } = props;
    const { t } = useTranslation(['dashboard', 'common']);

    const { cinema, cinemaConfiguration } = useCinemaStore();

    const [usersGroupLastUpdatedDatetime, setUsersGroupLastUpdatedDatetime] = useState<Date>();
    const [notificationsSentYesterday, setNotificationsSentYesterday] = useState<number>(0);
    const [lastNDays, setLastNDays] = useState<30 | 90 | 365>(30);

    const isMarketingAssistantActive = cinemaConfiguration?.hasMarketingAssistantBooked ?? undefined;
    const isLoadingMarketingAssistantIsActive = isMarketingAssistantActive === undefined;

    const { data: notificationsSentResponse, isLoading: isLoadingNotificationsSentResponse } = useQuery(
        ['notifications-sent-last-year', cinema],
        async () =>
            await getUserMoviesCountGrouped({
                group: AnalyticsApiGroup.NotificationDay,
                cinemaId: cinema?.id
            })
    );

    const notificationsSent = notificationsSentResponse || [];

    function getNotificationsSentDataItems(lastNDays: 1 | 30 | 90 | 365): { x: string; y: number }[] {
        let data = Array.from(Array(lastNDays).keys()).map((index) => {
            const todayMinusNDays = dateTodayMinus(index);
            todayMinusNDays.setHours(0, 0, 0, 0);
            const usersGroupItem = notificationsSent.find(
                (usersGroupItem) =>
                    formatDate_yyyyMMdd(new Date(usersGroupItem.group)) === formatDate_yyyyMMdd(todayMinusNDays)
            );
            if (usersGroupItem) {
                return {
                    x: usersGroupItem.group as string,
                    y: usersGroupItem.count
                };
            }
            return {
                x: formatDate_yyyyMMdd(todayMinusNDays),
                y: 0
            };
        });

        if (lastNDays === 365) {
            data = data.reduce((current, item) => {
                const monthForItem = new Date(item.x).getMonth();
                if (!(monthForItem in current)) {
                    current[monthForItem] = {
                        x: item.x.substring(0, 7),
                        y: item.y
                    };
                } else {
                    current[monthForItem] = {
                        x: current[monthForItem].x,
                        y: current[monthForItem].y + item.y
                    };
                }
                return current;
            }, []);
        }
        return data.sort((itemA, itemB) => (itemA.x < itemB.x ? -1 : 1));
    }

    function getNotificationsSentLastNDaysLabel(): string {
        let label: string = t('dashboard:notifications-sent');
        switch (lastNDays) {
            case 365:
                label += ` (${t('dashboard:last-year')})`;
                break;
            case 90:
                label += ` (${t('dashboard:last-90-days')})`;
                break;
            default:
                label += ` (${t('dashboard:last-30-days')})`;
                break;
        }
        return label;
    }

    function getNotificationsSentLastNDays(): number {
        return getNotificationsSentDataItems(lastNDays).reduce((current, item) => current + item.y, 0);
    }

    useEffect(() => {
        if (notificationsSentResponse) {
            setUsersGroupLastUpdatedDatetime(new Date());
            setNotificationsSentYesterday(
                notificationsSent?.find((v) => v.group === formatDate_yyyyMMdd(dateTodayMinus(1)))?.count ?? 0
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notificationsSentResponse]);

    const hasNotificationsSent = notificationsSent?.length > 0;

    return (
        <div className="space-y-24 xl:space-y-0 flex flex-col xl:space-x-24 xl:flex-row w-full">
            {isLoadingMarketingAssistantIsActive && (
                <AdminCard title={t('marketing-assistant')}>
                    <Image
                        className="animate-pulse"
                        src={placeholderImage}
                        width={1000}
                        height={1000}
                        alt="New Feature Coming Soon"
                    />
                </AdminCard>
            )}
            {!isLoadingMarketingAssistantIsActive &&
                (isMarketingAssistantActive ? (
                    <AdminCard
                        className="relative w-full"
                        isLoading={isLoadingNotificationsSentResponse}
                        leadingComponent={<MarketingAssistantActiveLabel />}
                        trailingComponent={
                            <span className="text-body-12 text-white-50">{`${t('dashboard:last-updated')}: ${
                                usersGroupLastUpdatedDatetime
                                    ? formatDate(usersGroupLastUpdatedDatetime, locale, {
                                          year: 'numeric',
                                          day: '2-digit',
                                          month: '2-digit',
                                          hour: '2-digit',
                                          minute: '2-digit'
                                      })
                                    : '-'
                            }`}</span>
                        }>
                        <div className="flex flex-col xl:flex-row xl:items-start space-y-16 xl:space-y-0">
                            <div className="flex flex-col space-y-24 w-200">
                                <InfoCardSmallNew
                                    label={t('dashboard:notifications-sent-yesterday')}
                                    value={notificationsSentYesterday}
                                    isLoading={isLoadingNotificationsSentResponse}
                                />
                                <InfoCardSmallNew
                                    label={getNotificationsSentLastNDaysLabel()}
                                    value={getNotificationsSentLastNDays()}
                                    isLoading={isLoadingNotificationsSentResponse}
                                />
                                <div className="flex-col w-200 h-240 hidden xl:flex">
                                    <Image
                                        className="absolute -bottom-[18rem] -left-[10rem] w-[39.5rem] h-[39.5rem] object-cover"
                                        src={ImMarketingAssistant.src}
                                        width={1000}
                                        height={1000}
                                        alt="Marketing Assistant Illustration"
                                    />
                                </div>
                            </div>

                            <div className="xl:w-0 flex-auto flex flex-col space-y-24">
                                <div className="flex flex-col xl:flex-row xl:justify-between xl:items-center space-y-8 xl:space-y-0">
                                    <span className="text-body-bold-14">{t('dashboard:notifications-sent')}</span>
                                    <Tabs
                                        tabs={[
                                            t('dashboard:last-30-days'),
                                            t('dashboard:last-90-days'),
                                            t('dashboard:last-year')
                                        ]}
                                        onTabItemClick={(index) => {
                                            switch (index) {
                                                case 1:
                                                    setLastNDays(90);
                                                    break;
                                                case 2:
                                                    setLastNDays(365);
                                                    break;
                                                default:
                                                    setLastNDays(30);
                                            }
                                        }}
                                    />
                                </div>
                                {hasNotificationsSent ? (
                                    <LineChart
                                        data={getNotificationsSentDataItems(lastNDays)}
                                        datasetLabel={t('dashboard:notifications')}
                                        style={{
                                            ticks: {
                                                color: tailwindColors.white['50']
                                            },
                                            borderColor: tailwindColors.cyan.DEFAULT
                                        }}
                                    />
                                ) : (
                                    <NoDataAvailableYet
                                        title={t('common:no-data-yet')}
                                        subtitle={t('marketing-assistant-no-data-yet')}
                                    />
                                )}
                            </div>
                        </div>
                    </AdminCard>
                ) : (
                    <MarketingAssistantInactiveCard
                        locale={locale}
                        t={t}
                    />
                ))}
        </div>
    );
}

export default CinemaDashboardMarketingAssistant;
