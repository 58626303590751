import classnames from 'classnames';

import { IconCircleSolid16 } from '../../../icons/symbols/ic-circleSolid';

type ActiveIndicatorProps = {
    isActive?: boolean;
    classNameActive?: string;
    classNameInactive?: string;
    labelActive?: string;
    labelInactive?: string;
    iconSize?: 'small' | 'default';
};

function ActiveIndicator(props: ActiveIndicatorProps) {
    const classNameActive = props.classNameActive || 'text-cyan';
    const classNameInactive = props.classNameInactive || 'text-white-50';

    let iconSize = 'w-12 h-12';

    switch (props.iconSize) {
        case 'small':
            iconSize = 'w-8 h-8';
            break;
        default:
            break;
    }

    return props.isActive ? (
        <div className={classnames('flex flex-row space-x-4 items-center', classNameActive)}>
            <div className="relative">
                <IconCircleSolid16 className={classnames('flex-none animate-pingWithPause', iconSize)} />
                <IconCircleSolid16 className={classnames('absolute top-0 left-0 flex-none', iconSize)} />
            </div>
            {props.labelActive && <span className="text-body-bold-12 italic">{props.labelActive}</span>}
        </div>
    ) : (
        <div className={classnames('flex flex-row space-x-4 items-center', classNameInactive)}>
            <IconCircleSolid16 className={classnames('flex-none', iconSize)} />
            {props.labelInactive && <span className="text-body-bold-12 italic">{props.labelInactive}</span>}
        </div>
    );
}

export default ActiveIndicator;
