import React from 'react';

import ActiveIndicator from '@cineamo/legacy-frontend-lib/src/components/ui/animation/ActiveIndicator';

function ProductStatus(props: { name: string; status: boolean }) {
    return (
        <div className="flex flex-row items-center space-x-8 py-8">
            <ActiveIndicator isActive={props.status} />
            <span className="text-body-12 font-montserrat-medium">{props.name}</span>
        </div>
    );
}

export default ProductStatus;
