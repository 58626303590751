import classnames from 'classnames';
import { TFunction } from 'next-i18next';
import React from 'react';
import { useQuery } from 'react-query';

import InfoCardSmall from '@cineamo/legacy-frontend-lib/src/components/ui/cards/InfoCardSmall';
import SkeletonView from '@cineamo/legacy-frontend-lib/src/components/ui/skeleton-view/SkeletonView';
import LinkWithStyle from '@/components/link/LinkWithStyle';

import { CinemaDto } from '@cineamo/legacy-frontend-lib/src/models/cinema/CinemaDto.types';
import { EventRequestDto } from '@cineamo/legacy-frontend-lib/src/models/event-request/EventRequestDto.types';

import { EventRequestState } from '@cineamo/legacy-frontend-lib/src/constants/request-constants';

import { EventRequestListResponse } from '@cineamo/legacy-frontend-lib/src/api/ApiListResponse.types';
import { ApiResponse } from '@cineamo/legacy-frontend-lib/src/api/ApiResponse.types';
import { getEventRequests } from '@cineamo/legacy-frontend-lib/src/api/event-request/event-request-api';

type CinemaDashboardYourEventsProps = {
    eventRequestsResponse: ApiResponse<EventRequestListResponse, undefined>;
    isLoadingEventRequests: boolean;
    cinema: CinemaDto;
    t: TFunction;
};
export function CinemaDashboardYourEvents(props: CinemaDashboardYourEventsProps) {
    const { eventRequestsResponse, isLoadingEventRequests, cinema, t } = props;

    const { data: eventRequestsCompletedResponse, isLoading: isLoadingEventRequestsCompleted } = useQuery(
        ['event-requests-completed', cinema],
        async () => {
            if (!cinema) {
                return null;
            }
            return await getEventRequests({
                cinemaId: cinema.id,
                isRegularShowtime: false,
                state: [EventRequestState.COMPLETED]
            });
        },
        {
            staleTime: 60000
        }
    );

    const eventRequests: EventRequestDto[] | [] = eventRequestsResponse?._embedded?.['event-requests'] || [];

    return (
        <div className="flex flex-col lg:flex-row">
            <div className="border-b-1 lg:border-b-0 pb-12 lg:pb-0 lg:border-r-1 border-white border-opacity-20 text-white space-y-16 pr-24">
                <InfoCardSmall
                    label={t('events-requested')}
                    value={eventRequestsResponse?._total_items ?? '-'}
                    isLoading={isLoadingEventRequests}
                />
                <InfoCardSmall
                    label={t('completed-events')}
                    value={eventRequestsCompletedResponse?._total_items ?? '-'}
                    isLoading={isLoadingEventRequestsCompleted}
                />
            </div>
            <div className="lg:ml-12 lg:w-2/3 relative">
                <div className={classnames('flex-1 text-white-50')}>
                    <div className={classnames('text-body-bold-10 uppercase tracking-wider whitespace-pre-wrap')}>
                        {t('last-10-requested-events')}
                    </div>
                    {isLoadingEventRequests ? (
                        <SkeletonView />
                    ) : (
                        <ol className="flex flex-col text-white">
                            {eventRequests?.map((eventRequest, index) => (
                                <li key={`request#${index}`}>
                                    <LinkWithStyle
                                        href={
                                            eventRequest._embedded?.cinema?.slug &&
                                            `/${eventRequest._embedded?.cinema?.slug}/event-requests/${eventRequest.id}`
                                        }>
                                        <span className="text-body-bold-14 clickable-element">{eventRequest.name}</span>
                                    </LinkWithStyle>
                                </li>
                            ))}
                        </ol>
                    )}
                </div>
                {/*<DashboardBarChart data={dummyBarChartData} />*/}
            </div>
        </div>
    );
}
