export enum EventRequestState {
    INITIAL = 'initial',
    NEW_STATE = 'new',
    AUTO_CHECK_RIGHTS = 'auto_check_rights',
    CHECK_RIGHTS = 'check_rights',
    CHECK_DATE = 'check_date',
    NO_RIGHTS = 'no_rights',
    DATE_DECLINED = 'date_declined',
    DATE_SUGGESTION_CINEMA = 'date_suggestion_cinema',
    ACCEPTED_CINEMA = 'accepted_cinema',
    ACCEPTED_CUSTOMER = 'accepted_customer',
    DECLINED_USER = 'declined_customer',
    DECLINED_CINEMA = 'declined_cinema',
    ALL_VALID = 'all_valid',
    PLANNED = 'event_planned',
    COMPLETED = 'event_completed',
    RESCHEDULE = 'event_reschedule',
    CANCELED = 'event_canceled',
    EXPIRED = 'event_expired'
}

export const RequestCategory = {
    CATEGORY_MOVIE_SERIES: {
        key: '0',
        string: 'Movie'
    },
    CATEGORY_GAMING: {
        key: '1',
        string: 'Gaming'
    },
    CATEGORY_OWN_CONTENT: {
        key: '2',
        string: 'Own content'
    },
    CATEGORY_PRESENTATION: {
        key: '3',
        string: 'Presentation'
    },
    CATEGORY_BUSINESS: {
        key: '4',
        string: 'Business'
    },
    CATEGORY_EXPLORE: {
        key: '5',
        string: 'Explore'
    },
    CATEGORY_EDUCATION: {
        key: '6',
        string: 'Education'
    }
};
