import { useRouter } from 'next-translate-routes';
import React from 'react';

import CinemaDashboard from '@/components/pages/dashboard/cinema-dashboard/CinemaDashboard';

import { parallel } from '@cineamo/legacy-frontend-lib/src/middleware/helpers';
import localeMiddleware from '@/src/middleware/localeMiddleware';
import withMiddlewareDefault from '@/src/middleware/withMiddlewareDefault';

function Home() {
    const router = useRouter();
    const locale = router.locale;

    return <CinemaDashboard locale={locale} />;
}

export default Home;

export const getServerSideProps = withMiddlewareDefault(
    parallel(localeMiddleware(['cinema', 'dashboard', 'marketing-assistant']))
);
