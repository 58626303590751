import classnames from 'classnames';
import { useTranslation } from 'next-i18next';
import Image from 'next/future/image';
import React, { useState } from 'react';
import { useQuery } from 'react-query';

import LinkWithStyle from '@/components/link/LinkWithStyle';
import CinemaDashboardMarketingAssistant from '@/components/pages/dashboard/cinema-dashboard/CinemaDashboardMarketingAssistant';
import { CinemaDashboardScheduledEvents } from '@/components/pages/dashboard/cinema-dashboard/CinemaDashboardScheduledEvents';
import { CinemaDashboardYourEvents } from '@/components/pages/dashboard/cinema-dashboard/CinemaDashboardYourEvents';
import DashboardAnalysisModal from '@/components/pages/dashboard/DashboardAnalysisModal';
import ProductStatusList from '@/components/ui/products/ProductStatusList';
import MadeWithLove from '@cineamo/legacy-frontend-lib/src/components/misc/MadeWithLove';
import DashboardUserGrowth from '@cineamo/legacy-frontend-lib/src/components/page/dashboard/DashboardUserGrowth';
import AdminCard from '@cineamo/legacy-frontend-lib/src/components/ui/cards/AdminCard';
import InfoCardSmall from '@cineamo/legacy-frontend-lib/src/components/ui/cards/InfoCardSmall';
import DotSeparatedLabel from '@cineamo/legacy-frontend-lib/src/components/ui/label/DotSeparatedLabel';

import { EventRequestDto } from '@cineamo/legacy-frontend-lib/src/models/event-request/EventRequestDto.types';

import { EventRequestState } from '@cineamo/legacy-frontend-lib/src/constants/request-constants';

import { getEventRequests } from '@cineamo/legacy-frontend-lib/src/api/event-request/event-request-api';
import { getUsers } from '@cineamo/legacy-frontend-lib/src/api/user/user-api';

import { cinemaIdOrSlug } from '@/src/helper/cinema-helper';
import { tailwindColors } from '@/src/helper/tailwindConfigHelper';
import { timeAgo } from '@/src/helper/time-helper';
import { loadAsset } from '@cineamo/legacy-frontend-lib/src/helper/asset-helper';
import {
    formatDate_yyyyMMddTHHmmssZ,
    getLogicalEndOfDay,
    getLogicalStartOfDay
} from '@cineamo/legacy-frontend-lib/src/helper/date-helper';

import useLastSelectedCinema from '@/src/hooks/useLastSelectedCinema';

import { LocaleMiddlewareProps } from '@/src/middleware/localeMiddleware';

import { useUserStore } from '@cineamo/legacy-frontend-lib/src/store/user/userStore';

import { IconForth16 } from '@cineamo/legacy-frontend-lib/src/icons/arrows/ic-forth';
import IconAnalysis24 from '@cineamo/legacy-frontend-lib/src/icons/ic-analysis';

const defaultUserImage = loadAsset('/images/placeholders/im-avatar.png');

type CinemaDashboardProps = LocaleMiddlewareProps;

function CinemaDashboard(props: CinemaDashboardProps): JSX.Element {
    const { locale } = props;

    const { t } = useTranslation(['dashboard', 'common']);

    const { user } = useUserStore();
    const { lastSelectedCinema: cinema } = useLastSelectedCinema(user);

    const cinemaId = cinema?.id;

    const [selectedDate, setSelectedDate] = useState<string | Date>(new Date());
    const [showAnalysisModal, setShowAnalysisModal] = useState<boolean>(false);

    const { data: eventRequestsByDatePlannedResponse, isLoading: isLoadingEventRequestsByDatePlanned } = useQuery(
        ['event-requests-by-date-planned', cinema, selectedDate],
        async () => {
            if (!cinema) {
                return null;
            }
            return await getEventRequests({
                cinemaId: cinemaId,
                isRegularShowtime: false,
                state: [EventRequestState.PLANNED, EventRequestState.ACCEPTED_CINEMA],
                showingDatetimeStart: formatDate_yyyyMMddTHHmmssZ(getLogicalStartOfDay(selectedDate)),
                showingDatetimeEnd: formatDate_yyyyMMddTHHmmssZ(getLogicalEndOfDay(selectedDate))
            });
        },
        {
            staleTime: 60000
        }
    );

    const eventRequestsByDatePlanned: EventRequestDto[] | [] =
        eventRequestsByDatePlannedResponse?._embedded?.['event-requests'] || [];

    const { data: eventRequestsResponse, isLoading: isLoadingEventRequests } = useQuery(
        ['event-requests', cinema],
        async () => {
            if (!cinema) {
                return null;
            }
            return await getEventRequests({
                cinemaId: cinemaId,
                isRegularShowtime: false,
                sort: 'createdDatetime',
                order: 'desc'
            });
        },
        {
            staleTime: 60000
        }
    );

    const { data: cinemaEployeesResponse, isLoading: isLoadingCinemaEmployees } = useQuery(
        ['cinema-employees', cinemaId],
        async () => {
            if (cinemaId === null || cinemaId === undefined) {
                return null;
            }
            return await getUsers({ employerCinema: cinemaId });
        },
        {
            staleTime: 1000 * 60 * 30, // 30 Minutes
            refetchOnWindowFocus: false
        }
    );

    const MAX_USERS_TO_RENDER = 4;
    const cinemaEmployees = cinemaEployeesResponse?._embedded?.users?.slice(0, MAX_USERS_TO_RENDER) ?? [];

    function AnalysisContents(props: { className?: string; labelClassName?: string; valueClassName?: string }) {
        const { labelClassName, valueClassName, className } = props;
        return (
            <div className={className}>
                <InfoCardSmall
                    label={t('showtimes-fetched')}
                    value={cinema?.showtimesUpdated ? timeAgo(cinema.showtimesUpdated, null, { t: t }) : '-'}
                    labelClassName={labelClassName && labelClassName}
                    valueClassName={valueClassName && valueClassName}
                    isLoading={false}
                />
                <InfoCardSmall
                    className="space-y-8"
                    label={
                        <LinkWithStyle
                            className="flex flex-row justify-between clickable-element"
                            href={`/${cinemaId}/users`}>
                            <DotSeparatedLabel
                                labels={[
                                    t('dashboard:my-cinema-team'),
                                    cinemaEployeesResponse?._total_items?.toString() ?? null
                                ]}
                            />
                            <IconForth16 className="text-white" />
                        </LinkWithStyle>
                    }
                    value={
                        <div className="flex flex-col space-y-16">
                            {cinemaEmployees.map((user, index) => (
                                <div key={`CinemaEmployee#${index}`}>
                                    <div className="flex flex-row space-x-8 items-center text-white">
                                        <Image
                                            className="rounded-full object-cover w-48 h-48"
                                            src={user.profileImageUrl ?? defaultUserImage}
                                            width={5000}
                                            height={5000}
                                            alt="user image"
                                        />
                                        <div className="flex flex-col space-y-2">
                                            <span className="text-body-bold-10">{user.username}</span>
                                            <span className="text-body-10 font-montserrat-medium">{user.email}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    }
                    labelClassName={labelClassName && labelClassName}
                    valueClassName={undefined}
                    isLoading={isLoadingCinemaEmployees}
                />
            </div>
        );
    }

    return (
        <main className="flex flex-col flex-auto">
            <DashboardAnalysisModal
                modalIsOpen={showAnalysisModal}
                setModalIsOpen={setShowAnalysisModal}>
                <AnalysisContents
                    className="space-y-24"
                    valueClassName="text-darkBlue"
                    labelClassName="text-darkBlue text-opacity-50"
                />
            </DashboardAnalysisModal>

            <div className="text-white flex flex-row h-0 flex-auto">
                <div className="w-0 h-full flex-auto p-24 overflow-auto">
                    <div className="pb-16 lg:pb-0 lg:hidden flex justify-end">
                        <div
                            className="cursor-pointer"
                            onClick={() => setShowAnalysisModal(true)}>
                            <IconAnalysis24 className="text-white" />
                        </div>
                    </div>

                    <div className="space-y-24">
                        <DashboardUserGrowth
                            cinema={cinema}
                            lineChartStyle={{
                                ticks: {
                                    color: tailwindColors.white['50']
                                },
                                borderColor: tailwindColors.cyan.DEFAULT
                            }}
                            moreAnalyticsUrl={`/${cinemaIdOrSlug(cinema)}/analytics`}
                            locale={locale}
                            t={t}
                        />

                        <CinemaDashboardMarketingAssistant locale={locale} />

                        <CinemaDashboardScheduledEvents
                            selectedDate={selectedDate}
                            setSelectedDate={setSelectedDate}
                            eventRequestsByDatePlanned={eventRequestsByDatePlanned}
                            isLoadingEventRequestsByDatePlanned={isLoadingEventRequestsByDatePlanned}
                            locale={locale}
                            t={t}
                        />

                        {/* TODO: Add when we have data or endpoint */}
                        <AdminCard
                            className="flex flex-col"
                            title={t('your-events')}>
                            <CinemaDashboardYourEvents
                                eventRequestsResponse={eventRequestsResponse}
                                isLoadingEventRequests={isLoadingEventRequests}
                                cinema={cinema}
                                t={t}
                            />
                        </AdminCard>
                    </div>
                </div>

                <div
                    className={classnames(
                        'h-full hidden lg:flex flex-col w-300 justify-between lg:border-l-1 border-white border-opacity-20 transform transition-all duration-300'
                    )}>
                    <AnalysisContents className="flex flex-col p-24 space-y-24 overflow-auto" />
                    <div className="p-24 space-y-32">
                        <ProductStatusList cinema={cinema} />
                        <MadeWithLove />
                    </div>
                </div>
            </div>
        </main>
    );
}

export default CinemaDashboard;
